import type { User as AuthUser } from 'firebase/auth';
import type { PartialDeep } from 'type-fest';
import { type Static, t } from 'elysia';
import type {
  mealTypes, systemMessageTypes, transactionStates, weekdays,
} from './system';
import type {
  createFoodHelper, diets, foodCategories, nutrientFields,
} from './food';
import type { SgdTokens } from './sgd';

export type MaybeUndefined<T> = T | undefined;

export type MaybeNull<T> = T | null;

export type GenericObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

// thanks to https://stackoverflow.com/a/61108377
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type Env = { [key: string]: string | undefined };

export type UserRole = 'consultant' | 'admin';

export interface KeyAndId {
  id: number;
  key: string;
}

export interface TypesenseApiKeys {
  readKey: KeyAndId;
  writeKey: KeyAndId;
}

export type TypesenseApiKeyType = keyof TypesenseApiKeys;

export interface Credentials {
  typesenseApiKeys: TypesenseApiKeys;
  sgdTokens?: SgdTokens;
}

export const subscriptionPlanCodes = ['basic', 'business'] as const;
export type SubscriptionPlanCode = typeof subscriptionPlanCodes[number];

export type SubscriptionPlanPeriod = 'monthly' | 'yearly';

export interface SubscriptionPlan {
  code: SubscriptionPlanCode;
  name: string;
  description: string;
  additionalInformation: string;
  price: {
    [key in SubscriptionPlanPeriod]: number;
  }
}

export interface BaseSubscription {
  id: string;
  mode: 'test' | 'live';
  createdAt: string;
  status: 'pending' | 'active' | 'canceled' | 'suspended' | 'completed';
  suspendedAt?: string;
  amount: {
    currency: string;
    value: string;
  },
  startDate: string;
  endDate?: string;
  nextPaymentDate: string;
}

export interface UserSubscription extends BaseSubscription {
  initialAmount: {
    currency: string;
    value: string;
  },
  plan: SubscriptionPlanCode;
  period?: SubscriptionPlanPeriod;
}

export interface MobileSubscription extends BaseSubscription {}

export type Locale = 'de' | 'en';

export type NutrientField = typeof nutrientFields[number];
export type NutrientFieldValues = {
  [fieldName in NutrientField]: number;
};

export type Diet = typeof diets[number];

export interface BaseInvoiceData {
  id: string;
  invoiceNumber?: string;
  date: string;
  paymentId?: string;
}

export const genders = ['male', 'female'] as const;
export type Gender = typeof genders[number];

export interface PostalAddress {
  country?: string;
  zipCode?: string;
  city?: string;
  street?: string;
}

export interface PersonalData extends PostalAddress {
  gender?: Gender;
  firstName?: string;
  surname?: string;
  company?: string;
  dateOfBirth?: string;
  email?: string;
  phone?: string;
}

export const onboardingSteps = [
  'complete_personal_data',
  'choose_favorite_food',
  'create_customer',
  'create_needs_analysis',
  'create_nutrition_plan',
  'create_recipe',
] as const;
export type OnboardingStep = typeof onboardingSteps[number];

export type OnboardingStepState = false | true | 'skipped';

export interface BaseProfile {
  personalData?: PersonalData;
  avatar?: string;
}

export const bmrFormulas = [
  'harris_benedict',
  'dge',
  'bmi',
  'de_lorenzo',
  'cunningham',
] as const;
export type BmrFormula = typeof bmrFormulas[number];

export const jobPalFactorsKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] as const;
export type JobPalFactorKey = typeof jobPalFactorsKeys[number];

export const freeTimePalFactorsKeys = [1, 2, 3, 4, 5] as const;
export type FreeTimePalFactorKey = typeof freeTimePalFactorsKeys[number];

export const needsAnalysisQuestionCategories = [
  'health',
  'eatingBehavior',
  'workout',
  'goals',
] as const;
export type NeedsAnalysisQuestionCategory = typeof needsAnalysisQuestionCategories[number];

export interface PostingAccount {
  name: string;
  number: number;
}

export interface CustomerInvoiceSettings {
  bank?: {
    name: string;
    iban: string;
    bic: string;
    accountOwner: string;
  };
  vat?: string;
  taxId?: string;
  taxRate?: number;
  paymentDeadlineInDays?: number;
  defaultPostingAccount?: number;
  currency: 'EUR' | 'USD' | 'CHF';
  showEmail?: boolean;
  email?: string;
  presets?: {
    headText?: string;
    footText?: string;
  };
}

export interface UserProfileDesignSettings {
  logo?: string;
  printable?: {
    primaryColor?: string
    primaryContrastColor?: string
  };
}

export interface UserProfileSettings {
  palFactors: {
    job: {
      [key in JobPalFactorKey]: number;
    };
    freeTime: {
      [key in FreeTimePalFactorKey]: number;
    };
  };
  microNutrientReferenceValues: Partial<NutrientFieldValues>;
  needsAnalysisQuestions: {
    [key in NeedsAnalysisQuestionCategory]: string[];
  };
  sidebarCollapsed?: boolean;
  accounting?: {
    accounts: PostingAccount[];
    invoice?: CustomerInvoiceSettings;
  };
  design?: UserProfileDesignSettings;
}

export const platformCodes = [
  '44zero',
  'otl',
  'ecodemy',
  'drfreese',
  'uvr',
  'sgd',
] as const;
export type PlatformCode = typeof platformCodes[number];

export type UserLevel = 0 | 1 | 2 | 3;

export interface BootstrapUserPayload {
  userId: string;
  trialExp: string; // iso
  utmTags?: string;
  locale?: Locale;
}

export interface SpecialAccess {
  until: string; // iso
  reason: string;
  plan: SubscriptionPlanCode;
  provider?: 'sgd';
  metaData?: {
    curriculumName?: string;
    enrollmentId?: number;
    courseId?: number;
    courseStart?: string; // iso
  },
}

export const PAYMENT_METHOD_CHANGE_REQUEST_STATUS = {
  WAITING_FOR_PAYMENT: 'waiting_for_payment',
  FAILED: 'failed',
} as const;

export type PaymentMethodChangeRequestStatus = EnumValues<typeof PAYMENT_METHOD_CHANGE_REQUEST_STATUS>;

export interface UserProfile extends BaseProfile {
  verifiedAt?: {
    timestamp: number;
    iso: string;
  };
  trialUntil?: string;
  trialPlan?: SubscriptionPlanCode;
  excludeFoodIds?: string[];
  favoriteFoodIds?: string[];
  favoriteRecipeIds?: string[];
  favoriteProductIds?: string[];
  likedRecipeIds?: string[];
  foodColumns?: NutrientField[];
  productColumns?: NutrientField[];
  mollieCustomerId?: string;
  subscription: UserSubscription;
  subscriptionsList: {
    [key: string]: {
      startDate: string;
      endDate?: string;
    }
  };
  mobileSubscription?: MobileSubscription;
  specialAccesses?: SpecialAccess[];
  locale: Locale;
  accounting?: {
    sevDeskContactId?: string;
    invoices?: BaseInvoiceData[];
    paymentMethod?: {
      mandateId?: string;
      changeRequest?: {
        status: PaymentMethodChangeRequestStatus;
        paymentId: string;
      }
    },
    payments?: {
      [key: string]: {
        createdAt: string;
        amount: {
          currency: string;
          value: string;
        },
        descriptions: string;
        method: string;
        metadata: {
          plan?: SubscriptionPlanCode;
          sevDeskContactId?: string;
          platform?: PlatformCode;
        };
        status: 'open' | 'canceled' | 'pending' | 'authorized' | 'expired' | 'failed' | 'paid';
        paidAt?: string;
      }
    }
  },
  gamification?: {
    redeemedLevel?: UserLevel;
    notificationSeenForLevel?: number;
  },
  settings: UserProfileSettings;
  onboardingSteps?: {
    [key in OnboardingStep]?: OnboardingStepState;
  };
  systemMessage?: string;
  seenNotifications?: string[];
}

export type UserActionReward = 'create_customer' | 'create_nutrition_plan' | 'create_needs_analysis' | 'create_recipe' | 'share_recipe';

type LoyaltyReward = 'weeks' | 'quarters' | 'years';

type SystemReward = 'validated' | 'google_review';

export interface PointRecord {
  action: UserActionReward | LoyaltyReward | SystemReward;
  points: number;
  created?: string;
}

export interface Points {
  computedPoints: PointRecord[];
  staticPoints: PointRecord[];
}

export type UserProfileArrayField = keyof Pick<UserProfile, 'excludeFoodIds' | 'favoriteFoodIds' | 'foodColumns'>;

export interface User {
  authUser: AuthUser;
  roles?: UserRole[];
  profile?: UserProfile;
  credentials?: Credentials;
  updateProfile: (update: PartialDeep<UserProfile>) => Promise<void>;
  addPointsForAction: (action: UserActionReward, points?: number) => Promise<void>;
  toggleFoodFavorite: (foodId: string) => Promise<void>;
  toggleRecipeFavorite: (recipeId: string) => Promise<void>;
  toggleProductFavorite: (productId: string) => Promise<void>;
  toggleRecipeLike: (recipeId: string, platform: PlatformCode, likeChange: 1 | -1) => Promise<void>;
  addRecipeUsage: (recipeId: string, platform: PlatformCode) => Promise<void>;
  submitRecipeToMainPlatform: (recipeId: string, platform: PlatformCode) => Promise<void>;
  getPoints: () => Promise<Points>;
  getTotalPoints: () => Promise<number>;
  getLevel: () => Promise<UserLevel>;
  completeOnboardingStep: (step: OnboardingStep) => Promise<void>;
  skipOnboardingStep: (step: OnboardingStep) => Promise<void>;
}

export interface SubscriptionFlowSetupPayload {
  plan: SubscriptionPlanCode;
  period?: SubscriptionPlanPeriod;
}

export interface SubscriptionUpgradePayload {
  plan: SubscriptionPlanCode;
  period?: SubscriptionPlanPeriod;
}

export interface GetUpgradeInfoPayload {
  currentPlanCode: SubscriptionPlanCode;
  newPlanCode: SubscriptionPlanCode;
  subscriptionId: string;
}

export interface UpgradeInfoResponse {
  newPrice: string;
  newPriceNet: string;
  upgradeFee: string;
  upgradeFeeNet: string;
}

export type DataSource = 'bls' | '44zero_base_db' | 'user_pool' | 'open_food_facts';

export type ColorCode = 'red' | 'purple' | 'yellow' | 'green';

export type FoodCategory = typeof foodCategories[number];

interface ColorCodeable {
  colorCoding?: ColorCode;
}

export type Food = {
  id: string;
  title: string;
  category: string;
  referenceUnit: string;
  diets: Diet[];
  source?: DataSource;
} & NutrientFieldValues & ColorCodeable & Verifiable;

export type FoodBaseField = keyof Food;

export type FoodHelper = ReturnType<typeof createFoodHelper>;

export const systemEmailCode = [
  'subscription_created',
  'subscription_recurring_payment_failed',
  'subscription_recurring_payment_failed_canceled',
  'subscription_recurring_payment_succeeded',
  'payment_method_changed',
  'payment_method_change_failed',
  'email_verification_request',
  'subscription_payment_method_update_succeeded',
  'subscription_upgrade_succeeded',
  'subscription_payment_charged_back',
  'admin_subscription_payment_charged_back',
  'mobile_customer_added_payment_succeeded',
  'mobile_subscription_recurring_payment_succeeded',
  'mobile_subscription_recurring_payment_failed',
] as const;
export type SystemEmailCode = typeof systemEmailCode[number];

export interface EmailTemplateAction {
  title: string;
  link: string;
}

export interface EmailTemplatePayload {
  userName?: string; // used for greeting
  actions?: EmailTemplateAction[];
  showClosing?: boolean;
  showDemoModeInfo?: boolean;
  [key: string]: unknown;
}

export type SystemMessageType = typeof systemMessageTypes[number];

export interface Message {
  type: SystemMessageType,
  text: string,
}

export const MOBILE_APP_CUSTOMER_STATUS = {
  NOT_INVITED: 'not_invited',
  WAITING_FOR_PAYMENT: 'waiting_for_payment',
  PAYMENT_FAILED: 'payment_failed',
  ACTIVE: 'active',
  UNLINKED: 'unlinked', // user was unlinked by the consultant
  USER_DELETED: 'user_deleted', // user has deleted himself in the mobile app
  IN_PROGRESS: 'in_progress', // invitation is currently running
} as const;

export type MobileAppCustomerStatus = EnumValues<typeof MOBILE_APP_CUSTOMER_STATUS>;

export interface MobileUserWebAppProfile {
  status?: MobileAppCustomerStatus;
  invitedAt?: string;
  unlinkedAt?: string;
  deletedAt?: string;
}

export interface CustomerProfile extends BaseProfile {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  mobile?: MobileUserWebAppProfile;
  notes?: string;
  isDemoAccount?: boolean;
}

export interface Customer {
  id: string;
  profile?: CustomerProfile;
}

export const breastfeedingOptions = [
  false,
  'up_to_4_month',
  'after_4_month_full',
  'after_4_month_partial',
] as const;
export type BreastfeedingOption = typeof breastfeedingOptions[number];

export interface NeedsAnalysisSport {
  id: string;
  amount: number;
}

export const goalTypes = [
  'gain_weight',
  'keep_weight',
  'loose_weight',
] as const;
export type GoalType = typeof goalTypes[number];

export interface NeedsAnalysisQuestionEntry {
  question: string;
  answer: string;
}

export interface NeedsAnalysis {
  id: string;
  customerId: string;
  createdAt: string;
  updatedAt: string;
  redeemedPoints?: boolean;
  bodyWeight?: number;
  bodySize?: number;
  bodyFat?: number;
  considerFatFreeMass?: boolean;
  hipCircumference?: number;
  waistCircumference?: number;
  breastfeeding?: BreastfeedingOption;
  pregnant?: boolean;
  formOfNutrition?: string;
  physicalLoad?: {
    job?: JobPalFactorKey;
    freeTime?: FreeTimePalFactorKey;
  },
  sports?: NeedsAnalysisSport[];
  bmrFormula?: BmrFormula;
  dailySchedule?: {
    sleep: number;
    work: number;
    freeTime: number;
  },
  goal?: {
    type: GoalType,
    amount?: number;
    start?: string;
    deadline?: string;
    otherGoals?: string;
  };
  manualEnergyAdjustment?: number;
  questions?: {
    [key in NeedsAnalysisQuestionCategory]?: NeedsAnalysisQuestionEntry[];
  },
  createdByCustomer?: boolean;
}

export interface NeedsAnalysisCalculations {
  fatFreeMass: number;
  thq: number;
  bmi: number;
  bmiLevel?: string;
  weeksUntilGoalDeadline: number;
  daysUntilGoalDeadline: number;
  bmr: number;
  pregnancyDemand: number;
  breastfeedingDemand: number;
  bmrTotal: number;
  individualPalValue: number;
  bmrTotalWithPal: number;
  sportsDemand: number;
  energyDemandSubtotal: number;
  goalDemand: number;
  energyDemandTotal: number;
  energyAvailabilityThreshold: number;
}

export interface NeedsAnalysisWithCalculations extends NeedsAnalysis {
  calculations: NeedsAnalysisCalculations;
}

export interface NeedsAnalysisObject extends NeedsAnalysis {
  customer?: CustomerProfile;
  customerName: () => MaybeUndefined<string>;
  update: (data: Partial<NeedsAnalysis>) => Promise<void>;
  fatFreeMass: () => number;
  thq: () => number;
  bmi: () => number;
  bmiLevel: () => MaybeUndefined<string>;
  weeksUntilGoalDeadline: () => number;
  daysUntilGoalDeadline: () => number;
  bmr: () => number;
  pregnancyDemand: () => number;
  breastfeedingDemand: () => number;
  bmrTotal: () => number;
  individualPalValue: () => number;
  bmrTotalWithPal: () => number;
  sportsDemand: () => number;
  energyDemandSubtotal: () => number;
  goalDemand: () => number;
  energyDemandTotal: () => number;
  energyAvailabilityThreshold: () => number;
  percentageCompleted: () => number;
  jobPALFactorOptions: () => {
    [key in JobPalFactorKey]: string;
  };
  freeTimePALFactorOptions: () => {
    [key in FreeTimePalFactorKey]: string;
  };
}

export const customerLogEntryTypes = [
  'body_weight',
] as const;
export type CustomerLogEntryType = typeof customerLogEntryTypes[number];

export interface CustomerLogEntry {
  id: string;
  customerId: string;
  createdAt: string;
  updatedAt: string;
  date: string;
  type: CustomerLogEntryType;
  value: number | string;
  customer?: CustomerProfile;
}

export interface ActivityPlanEntry {
  what: string;
  who: string;
  when: string;
  how: string;
}

export interface ActivityPlan {
  id: string;
  customerId: string;
  createdAt: string;
  updatedAt: string;
  entries: ActivityPlanEntry[];
  customer?: CustomerProfile;
}

export interface Ingredient extends Food {
  amount: number;
}

export type RecordVisibility = 'public' | 'private';

export interface Recipe extends ColorCodeable, Verifiable {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  title: string;
  content: string;
  photo?: string;
  servings: number;
  mealTypes: string[];
  tags: string[];
  books: string[];
  ingredients: Ingredient[];
  source: DataSource;
  ownerUid?: string;
  visibility?: RecordVisibility;
  likes?: number;
  uses?: number;
  platform?: PlatformCode; // platform where the recipe originated
}

export interface LikesForRecord {
  recordId: string;
  likes: number;
}

export function isRecipe(arg: Food | Recipe): arg is Recipe {
  return !!(arg as Recipe).ingredients;
}

export function isFood(arg: Food | Recipe): arg is Food {
  return !isRecipe(arg);
}

export type Weekday = typeof weekdays[number];

export type MealType = typeof mealTypes[number];

interface Verifiable {
  verified?: boolean;
}

export interface ProductMetaInfo {
  metaInfo?: {
    ai?: {
      model: string;
      tokenUsage?: number;
    }
  }
}

export interface Product extends ColorCodeable, Verifiable, ProductMetaInfo {
  id: string;
  title: string;
  brands?: string;
  referenceUnit: string;
  image?: string;
  completeness: number;
  score: number;
  source: DataSource;
  sugar: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  salt: number;
  dietaryFiber?: number;
  fattyAcidsSaturated: number;
  fattyAcidsMonounsaturated?: number;
  fattyAcidsPolyunsaturated?: number;
  energyCal: number;
  energyKilojoules: number;
}

export type MappedProduct = {
  id: string;
  gtin: string;
  title?: string;
  image?: string;
  referenceUnit: string;
  brands?: string;
  completeness: number;
  score: number;
  source: string;
  verified?: boolean;
  type?: 'supplement';
} & NutrientFieldValues & ProductMetaInfo;

export type OFFProduct = {
  product_name_de?: string;
  product_name_fr?: string;
} & Record<string, any>;

export type EatableType = 'food' | 'recipe' | 'product';

export interface EatableHit {
  id: string;
  title: string;
  type: EatableType;
  isFavorite: boolean;
  isOwn: boolean;
  isCommunity?: boolean;
  likes?: number;
  collection: string;
  document?: Recipe | Food | Product;
}

export interface ExtendedEatable extends EatableHit {
  uid: string;
  nutrients?: Partial<NutrientFieldValues>;
}

export interface MealEntry {
  uid?: string;
  sort: number;
  amount: number;
  data: ExtendedEatable;
}

export interface Meal {
  mealType: MealType;
  weekday: Weekday;
  time?: string;
  entries?: {
    [key: string]: MealEntry;
  };
}

export interface NutritionPlanNote {
  weekday: Weekday;
  content: string;
}

export interface NutritionPlan {
  id: string;
  createdAt: string;
  updatedAt: string;
  customerId?: string;
  title?: string;
  weekdays?: Weekday[];
  mealTypes?: MealType[];
  meals?: {
    [key: string]: Meal;
  };
  notes?: {
    [key in Weekday]: string;
  };
  redeemedPoints?: boolean;
  optimalMacronutrientDistribution?: {
    fat: number;
    protein: number;
  };
  settings?: {
    mobile?: {
      syncNotes?: boolean;
    }
  }
}

export interface NutritionPlanObject extends NutritionPlan {
  customer?: Customer;
  needsAnalysis?: NeedsAnalysisObject,
  update: (data: PartialDeep<NutritionPlan>) => Promise<void>;
}

export interface NutritionPlanPdfOptions {
  showKcal?: boolean;
  showCharts?: boolean;
  showWeekView?: boolean;
  showNotes?: boolean;
  recalculateRecipes?: boolean;
}

export interface RecipePdfOptions {
  showKcal?: boolean;
  recalculateRecipes?: boolean;
}

export interface GetNutrientsOptions {
  applyMultiplicationFactors?: boolean;
  decimals?: number;
}

export interface NewsEntry {
  id: number;
  created: string;
  publishDate: string;
  title: string;
  teaser: string;
  backlink?: string;
  content?: string;
  image?: {
    thumbnail: string;
    src: string;
  }
  notification: MaybeNull<{
    uid: string;
    versionGte: MaybeNull<string>;
    onPage: MaybeNull<string>;
  }>
}

export type TransactionState = typeof transactionStates[number];

export type TransactionType = 'invoice' | 'expense';

export interface Transaction {
  id: string;
  transactionDate: string | number;
  title: string;
  state: TransactionState;
  type: TransactionType;
  postingAccountNumber: number;
  postingAccount: string;
}

export interface TaxableAmount {
  amount: number;
  taxRate: number;
}

export interface Expense extends Transaction, TaxableAmount {
  type: 'expense';
  documentId?: string;
  customerId?: string;
  customerName?: string;
}

export interface CustomerInvoicePosition extends TaxableAmount {
  id: string;
  title: string;
  quantity: number;
}

export interface CustomerInvoice extends Transaction, PostalAddress {
  type: 'invoice';
  documentId: string;
  customerId: string;
  customerName: string;
  headText?: string;
  footText?: string;
  servicePeriod: string;
  paymentDeadline: string;
  positions: CustomerInvoicePosition[];
  amount?: number; // can hold total amount
}

export interface CustomerInvoiceAmounts {
  formatted: {
    net: string;
    tax: string;
    total: string;
  };
  plain: {
    total: number;
  }
}

export type AccountingSummaryCategory = {
  total: number;
  open: number;
  paid: number;
};

export interface AccountingSummary {
  expenses: AccountingSummaryCategory;
  revenues: AccountingSummaryCategory;
  profit: number;
  accounts: {
    label: string;
    amount: number;
  }[];
}

export interface SportType {
  id: number;
  name: {
    de: string;
    en: string;
  },
  met: number;
}

export interface AppConfig {
  mealTypes: string[];
  recipeBooks: string[];
  tags: [];
  sportTypes: SportType[];
  subscriptionPlans: SubscriptionPlan[];
}

export interface VerificationEmailPayload {
  continueUrl: string;
  utmTags?: string;
}

export type EnumValues<T> = T[keyof T];

export const ENVIRONMENT = {
  DEVELOP: 'develop',
  STAGING: 'staging',
  PRODUCTION: 'production',
} as const;

export type Environment = EnumValues<typeof ENVIRONMENT>;

export const FIREBASE_PROJECT_TYPE = {
  WEB: 'web',
  MOBILE: 'mobile',
} as const;

export type FirebaseProjectType = EnumValues<typeof FIREBASE_PROJECT_TYPE>;

export type ConsumableCategoryEntryKey = `${EatableType}:${string}`;

export type OnAfterLoginRequest = {
  userId: string;
};

export type LookupConsultantByEmailRequest = {
  email: string;
};

export type LookupConsultantByEmailResponse = {
  email: string;
  firstName: string;
  surname: string;
};

export const AppUserDTO = t.Object({
  customerId: t.String({
    minLength: 20,
  }),
  consultantId: t.String({
    minLength: 28,
  }),
  requireNeedsAnalysis: t.Optional(t.Boolean({
    default: false,
  })),
});

export const UnlinkUserFromConsultantDTO = t.Object({
  customerId: t.String({
    minLength: 20,
  }),
  consultantId: t.String({
    minLength: 28,
  }),
});

export const AppUserRequiredActionDTO = t.Union([
  t.Literal('change_password'),
  t.Literal('confirm_consultant'),
  t.Literal('create_needs_analysis'),
  t.Literal('unlink_consultant'),
]);

export type AppUserRequiredAction = Static<typeof AppUserRequiredActionDTO>;

export type AppUser = Omit<Static<typeof AppUserDTO> & {
  firstName: string;
  surname: string;
  email: string;
  requiredUserActions: AppUserRequiredAction[];
  platform: string;
  selectedNutritionPlanId?: string;
  recentlyUsed: ConsumableCategoryEntryKey[];
  favorites: ConsumableCategoryEntryKey[];
  locale: Locale;
  invitedAt?: string; // ISO date
  firstLoginAt?: string; // ISO date
  isDemoAccount?: boolean;
}, 'customerId' | 'requireNeedsAnalysis'>;

export const ConsultantDTO = t.Object({
  id: t.String(),
  displayName: t.String(),
  email: t.Optional(t.String()),
  photoUrl: t.Optional(t.String()),
  personalData: t.Optional(
    t.Record(t.String(), t.Any()),
  ),
  design: t.Optional(
    t.Object({
      logo: t.Optional(t.String()),
      printable: t.Optional(t.Object({
        primaryColor: t.Optional(t.String()),
        primaryContrastColor: t.Optional(t.String()),
      })),
    }),
  ),
});

export type Consultant = Static<typeof ConsultantDTO>;

export const GetPlanSyncStateDTO = t.Object({
  customerId: t.String({
    minLength: 20,
  }),
  nutritionPlanId: t.String({
    minLength: 20,
  }),
});

export const SyncPlanDTO = t.Composite([
  GetPlanSyncStateDTO,
  t.Object({
    consultantId: t.String({
      minLength: 28,
    }),
    syncNotes: t.Optional(t.Boolean()),
  }),
]);

export const ConsumableSearchResultDTO = t.Object({
  type: t.String(),
  id: t.String(),
  title: t.String(),
  info: t.Optional(t.String()),
  imageUrl: t.Optional(t.String()),
  textMatch: t.Number(),
  document: t.Record(
    t.String(),
    t.Any(),
  ),
});

export type ConsumableSearchResult = Omit<Static<typeof ConsumableSearchResultDTO>, 'textMatch'> & {
  document: Partial<Food | Recipe | Product>,
  textMatch?: number;
};

export const SearchFiltersDTO = t.Object({
  category: t.Optional(t.Literal('drinks')),
});

export type SearchFilters = Static<typeof SearchFiltersDTO>;
