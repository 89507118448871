<script setup lang="ts">
import { computed, defineAsyncComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { DateTime } from 'luxon';
import { routes } from '@/router';
import { logout } from '@/lib/firebase/auth';
import BrandIcon from '@/components/brand/BrandIcon.vue';
import AppAvatar from '@/components/avatar/AppAvatar.vue';
import { useAuth } from '@/composables/firebase/auth';
import { useGamification } from '@/composables/gamification';
import DropdownMenu from '@/components/generic/DropdownMenu.vue';
import { updateConsultant } from '@/lib/firebase/store';
import { is44zero } from '@/lib/platform';
import UserGamification from '@/components/gamification/UserGamification.vue';

const WhiteLabelLogo = defineAsyncComponent(() => import('@/components/white-label/WhiteLabelLogo.vue'));

const route = useRoute();
const isVisible = computed(() => (typeof route.meta.hideAppSidebar === 'boolean' ? !route.meta.hideAppSidebar : true));
const mainMenuRoutes = routes.filter((route) => route.meta?.menu?.position === 'main');
const showUserMenu = ref(false);
const isProfilePage = computed(() => route.name === 'profile');

const { user: fullUser } = useAuth();
const user = computed(() => fullUser.value?.authUser);

const { currentLevel } = useGamification();

// eslint-disable-next-line arrow-body-style
const showPoweredBy = computed(() => {
  return import.meta.env.VITE_SHOW_POWERED_BY === 'true';
});

const isCollapsed = ref(false);

if (fullUser.value?.profile?.settings?.sidebarCollapsed) {
  isCollapsed.value = true;
}

const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value;

  if (fullUser.value?.authUser?.uid) {
    updateConsultant(fullUser.value.authUser.uid, {
      settings: {
        sidebarCollapsed: isCollapsed.value,
      },
    });
  }
};

const currentYear = DateTime.now().toFormat('yyyy');
</script>

<template>
  <aside
    id="app-sidebar"
    :class="{ 'app-sidebar--collapsed': isCollapsed }"
    v-if="isVisible">
    <a
      href=""
      class="logo-link"
      @click.prevent="toggleCollapse">
      <BrandIcon
        v-if="is44zero"
        variant="primary" />

      <WhiteLabelLogo
        v-else />
    </a>

    <nav
      id="app-main-nav"
      :key="`app-main-nav-${isCollapsed}`"
      data-simplebar>
      <ul>
        <li
          v-for="row in mainMenuRoutes"
          :key="row.name">
          <RouterLink
            :to="row.path">
            <VTooltip
              placement="right"
              theme="secondary-tooltip"
              :distance="27"
              v-if="isCollapsed"
              :popper-triggers="['hover']">
              <component :is="row.meta?.menu?.icon" />

              <template #popper>
                <p class="text-s">
                  <span>{{ row.meta?.menu?.title }}</span>
                </p>
              </template>
            </VTooltip>

            <template v-else>
              <component :is="row.meta?.menu?.icon" />
              <span>{{ row.meta?.menu?.title }}</span>
            </template>
          </RouterLink>
        </li>
      </ul>
    </nav>

    <div class="app-sidebar-footer">
      <div id="user-gamification-container">
        <UserGamification
          show-points
          size="xs" />
      </div>

      <VDropdown
        id="profile-container"
        placement="right-end"
        theme="popover"
        :skidding="-8"
        :class="{ active: isProfilePage }">
        <button>
          <div class="user-info">
            <div v-if="fullUser?.profile?.personalData?.firstName">
              {{ fullUser.profile.personalData.firstName }}
            </div>
            <span class="text-light text-s">{{ user?.email }}</span>
          </div>
          <AppAvatar
            v-if="fullUser"
            class="ml-2 flex-none"
            :size="36" />
        </button>

        <template #popper>
          <dropdown-menu :min-width="240">
            <template #default>
              <div class="dropdown-menu-header flex items-center justify-between">
                <div>
                  <div class="text-s mb-2">
                    {{ $t(`gamification.levels.${currentLevel}`) }}
                  </div>
                  <div v-if="fullUser?.profile?.personalData?.firstName">
                    {{ fullUser.profile.personalData.firstName }}
                  </div>
                  <span class="text-light">{{ user?.email }}</span>
                </div>
                <AppAvatar
                  v-if="fullUser"
                  class="ml-3"
                  :size="48" />
              </div>
              <div>
                <RouterLink
                  to="/profile"
                  v-close-popper
                  @click="showUserMenu = false">
                  {{ $t('routes.profile.menuTitle') }}
                </RouterLink>
              </div>

              <div>
                <a
                  href="https://kcalculator.de/hilfe">
                  {{ $t('AppSidebar.helpCta') }}
                </a>
              </div>
              <div>
                <a
                  href="https://kcalculator.de/partnerprogramm">
                  {{ $t('AppSidebar.partnerProgramCta') }}
                </a>
              </div>
              <div>
                <a
                  href="#"
                  v-close-popper
                  @click.prevent="logout">{{ $t('AppSidebar.logout') }}</a>
              </div>
            </template>
          </dropdown-menu>
        </template>
      </VDropdown>

      <div
        v-if="!isCollapsed"
        class="app-powered-by">
        <template v-if="showPoweredBy">
          Powered by <a
            href="https://www.kcalculator.de/"
            target="_blank"
            rel="noopener noreferrer">kcalculator</a>
        </template>
        <template v-else>
          &copy; {{ currentYear }} <a
            href="https://www.kcalculator.de/"
            target="_blank"
            rel="noopener noreferrer">kcalculator</a> by 44zero
        </template>
      </div>
    </div>
  </aside>
</template>

<style>
#app-sidebar {
  flex: 0 0 13rem;
  width: 0;
  background-color: var(--color-sidebar);
  padding: var(--space-4) var(--space-3) 0;
  align-self: stretch;
  border-right: 2px solid var(--color-sidebar-shade);
  display: flex;
  flex-direction: column;
  transition: all var(--transition-duration-default) var(--transition-animation-default);
  position: relative;

  .logo-link {
    display: flex;
    flex: none;
    justify-content: center;
    margin: var(--space-4) 0;
    width: 100%;

    .brand-icon {
      width: 3rem;
    }

    .whitelabel-logo {
      width: 80%;
    }
  }

  nav#app-main-nav {
    flex: 1 1 auto;
    margin: var(--space-3) 0;
    overflow-y: auto;
    height: 0;

    --icon-size: 1.75rem;

    ul {
      padding: 0;

      li {
        list-style: none;
        padding: var(--space-2) 0;

        a {
          color: var(--color-sidebar-contrast);
          text-decoration: none;
        }

        > a, > div {
          display: flex;
          align-items: center;
          padding: var(--space-2) var(--space-3);
          border-radius: var(--border-radius-rounded);
          opacity: 0.9;
          font-weight: var(--font-weight-bold);
          font-size: var(--font-size-m);
          transition: all var(--transition-duration-default) var(--transition-animation-default);
          transition-property: background-color, border-color, opacity;
          overflow: hidden;

          &:hover, &.router-link-active {
            background-color: var(--color-sidebar-tint);
            opacity: 1;
          }

          .kc-icon, .brand-icon {
            height: var(--icon-size);
            width: var(--icon-size);
            margin-right: var(--space-3);
            --icon-stroke-width: 12px;
            stroke: var(--color-sidebar-contrast);
            flex: none;
          }

          .brand-icon {
            fill: var(--color-sidebar-contrast);
          }

          span {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          svg {
            outline: none;
          }
        }

        .disabled-entry {
          opacity: .5 !important;
        }
      }
    }
  }

  .app-powered-by {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-bold);
    margin: 0 calc(-1 * var(--space-3));
    padding: var(--space-2) var(--space-4);
    background: var(--color-sidebar-shade);
    text-align: center;
    color: var(--color-sidebar-contrast);

    a {
      text-decoration: none;
      color: var(--color-primary);
      transition: color var(--transition-duration-default) var(--transition-animation-default);

      &:hover {
        color: var(--color-primary-shade);
      }
    }
  }

  &.app-sidebar--collapsed {
    flex: 0 0 4rem;
    padding-left: var(--space-2);
    padding-right: var(--space-2);
    align-items: center;

    .logo-link {
      width: 2rem;
    }

    #app-main-nav {
      --icon-size: 1.5rem;
      width: 40px;

      ul li {
        > a, > div {
          padding: var(--space-2);

          .kc-icon, .brand-icon {
            margin-right: 0;
          }

          .v-popper {
            display: flex;
          }

          > span {
            display: none;
          }
        }
      }
    }

    .app-powered-by {
      display: none;
    }

    .app-sidebar-footer {
      flex: none;
      align-self: stretch;
    }

    #profile-container {
      margin: 0 calc(-1 * var(--space-2) - 2px);
      border-right: 2px solid var(--color-sidebar);

      &.active {
        border-right-color: var(--color-sidebar-tint);
      }

      button {
        width: 100%;
        margin: 0;
        justify-content: center;

        div.user-info {
          display: none;
        }

        div:last-child {
          margin-left: 0;
        }
      }
    }

    #user-gamification-container {
      margin: 0 auto var(--space-3);
      padding: 0;

      .content {
        display: none;
      }
    }
  }
}
#profile-container {
  margin: 0 calc(-1 * var(--space-3)) 0;
  padding: var(--space-3) 0;

  button {
    text-align: left;
    background: transparent;
    border: 0;
    outline: 0;
    margin: 0 var(--space-3);
    color: var(--color-sidebar-contrast);
    width: calc(100% - var(--space-3) * 2);
    display: flex;
    align-items: center;
    padding: 0;

      &:hover {
        cursor: pointer;
      }

      div.user-info {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1 1 auto;
      }
    }

  &.active {
    background: var(--color-sidebar-tint);
  }
}

#user-gamification-container {
  margin: 0 calc(-1 * var(--space-3)) var(--space-3);
  padding: 0 var(--space-2);

  .current-level {
    --font-size: var(--font-size-m);
    flex-direction: row-reverse;
    justify-content: space-between;
    color: white;

    .icon-wrapper svg * {
      stroke: var(--color-white);
    }
  }
}
</style>
