<script setup lang="ts">
import { generateUUID } from '@kcalc/lib/browser';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: undefined,
  },
  description: {
    type: String,
    required: false,
    default: undefined,
  },
  modelValue: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const uuid = `field-${generateUUID()}`;

const toggle = () => {
  emit('update:modelValue', !props.modelValue);
};
</script>

<template>
  <div
    class="toggle-control"
    :class="{ 'toggle-control--active': modelValue, 'toggle-control--disabled': disabled }">
    <button
      :id="uuid"
      @click="toggle">
      <span class="toggle" />
    </button>

    <label
      v-if="label || description"
      :for="uuid">
      <span v-if="label">{{ label }}</span>
      <span
        v-if="description"
        class="description">
        {{ description }}
      </span>
    </label>
  </div>
</template>

<style>
.toggle-control {
  display: flex;
  align-items: center;

  --control-size: 1.75rem;
  --toggle-size: 1.25rem;
  --min-width: 3.5rem;
  --border-size: 2px;

  --toggle-spacing-x: var(--space-1);
  --label-size: var(--font-size-m);
  --translate-x: calc(var(--control-size) - var(--toggle-spacing-x));

  --background: var(--color-gray-ultra-light);
  --background-active: var(--color-secondary);

  --border-color: var(--color-gray-light);
  --border-color-active: var(--color-secondary-shade);

  --toggle-color: var(--color-muted);
  --toggle-color-active: var(--color-primary);

  --toggle-border-color: var(--color-gray-light);
  --toggle-border-color-active: var(--color-secondary-shade);

  &--small {
    --control-size: 1.5rem;
    --toggle-size: 1rem;
    --min-width: 3rem;
    --label-size: var(--font-size-s);
    --translate-x: calc(var(--control-size) - var(--toggle-spacing-x));
  }

  button {
    height: var(--control-size);
    background: var(--background);
    display: inline-flex;
    border-radius: var(--border-radius-rounded);
    border: 2px solid var(--border-color);
    position: relative;
    min-width: var(--min-width);
    transition: all var(--transition-duration-default) var(--transition-animation-default);
    cursor: pointer;
    will-change: background-color, border-color;

    .toggle {
      display: block;
      height: var(--toggle-size);
      width: var(--toggle-size);
      background: var(--toggle-color);
      border-radius: 100%;
      position: absolute;
      left: var(--toggle-spacing-x);
      top: 50%;
      transform: translateY(-50%);
      transition: all var(--transition-duration-default) var(--transition-animation-default);
      border: 2px solid var(--toggle-border-color);
      will-change: border-color, background-color, transform;
    }
  }

  &--active {
    button {
      background-color: var(--background-active);
      border-color: var(--border-color-active);

      .toggle {
        background-color: var(--toggle-color-active);
        transform: translate(var(--translate-x), -50%);
        border-color: var(--toggle-border-color-active);
      }
    }
  }

  &--disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  label {
    margin-left: var(--space-2);
    font-size: var(--label-size);

    span.description {
      font-size: var(--font-size-xs);
      display: block;
    }
  }
}
</style>
